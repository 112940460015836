import { useVendorDataContext } from '_contexts/VendorDataContext';
import React, { useEffect, useState } from 'react';

const EmbeddedRouteGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { vendorProfile } = useVendorDataContext();
    const [routeFlag, setRouteFlag] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (vendorProfile !== undefined) {
            // const enableFlag: boolean = vendorProfile?.org_name === 'Airlegit';
            const enableFlag: boolean = !!vendorProfile?.org_name;
            setRouteFlag(enableFlag);
        }
    }, [vendorProfile]);

    return routeFlag !== undefined ? <>{routeFlag ? children : <AccessNotAllowed />}</> : <CheckingAccess />;
}

export default EmbeddedRouteGuard;

const AccessNotAllowed: React.FC = () => {
    return (<div>
        <h1>Access Not Allowed</h1>
        <p>You are not allowed to access this page.</p>
    </div>);
}

const CheckingAccess: React.FC = () => {
    return (<div>Authenticating Vendor...</div>);
}