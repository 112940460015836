import { GridColDef } from '@mui/x-data-grid';
import { IChannelData, ICommunicationPreferences, IContextTypeData, INavbarLinkUpper, IPricing, IProductTypeData, ISupport, ITripDisplayFields, ITripFlightSegment, ITripMandatoryFields, ITripPassenger, ITripPassengerExtra, ITripRequest, ITripResponse } from '_models/vendor.interface';
import { IPassenger, IAddress, IFlightSegment, ITemplate, ITemplateData } from '_models/vendor.interface';
import { IVendorData } from '_models/vendor.interface';

export class VendorConstants {
    static fromNumber = '2566869131';
    static assignPrimaryPassenger = (): IPassenger => {
        let primaryPassenger: IPassenger = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_PASSENGER));
        primaryPassenger.primary = true;
        return primaryPassenger;
    }
    static assignTripPrimaryPassenger = (): ITripPassenger => {
        let primaryPassenger: ITripPassenger = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER));
        primaryPassenger.primary = true;
        return primaryPassenger;
    }

    static DEFAULT_ADDRESS: IAddress = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
    };
    static DEFAULT_PASSENGER: IPassenger = {
        gender: '',
        email: '',
        firstname: '',
        middlename: '',
        lastname: '',
        phoneNumber: '',
        phoneCode: '',
        dob: '',
        address: JSON.parse(JSON.stringify(VendorConstants.DEFAULT_ADDRESS)),
        primary: false
    };
    static DEFAULT_TRIP_PASSENGER: ITripPassenger = {
        gender: '',
        email: '',
        firstname: '',
        middlename: '',
        lastname: '',
        phoneNumber: '',
        phoneCode: '',
        dob: '',
        address: JSON.parse(JSON.stringify(VendorConstants.DEFAULT_ADDRESS)),
        primary: false,
        nationality: '',
        passportNo: '',
        passportIssueDate: '',
        passportExpDate: '',
        ktn: '',
    };
    static DEFAULT_SUPPORT: ISupport = {
        gender: '',
        email: '',
        firstname: '',
        middlename: '',
        lastname: ''
    };
    static DEFAULT_TRIP_PASSENGER_EXTRA: ITripPassengerExtra = {
        eticket: ''
        // ff: '',
        // prefered_seat: ''
    }
    static DEFAULT_FLIGHT_SEGMENT: IFlightSegment = {
        airline: '',
        flightno: '',
        from: '',
        to: '',
        deptdate: '',
        depttime: ''
    };
    static DEFAULT_TRIP_FLIGHT_SEGMENT: ITripFlightSegment = {
        airline: '',
        flightno: '',
        from: '',
        to: '',
        deptdate: '',
        depttime: '',
        pnr: '',
        passengerExtra: [JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA))]
    };
    static DEFAULT_TRIP_PRICING: IPricing = {
        passengerCount: 0,
        price: '',
        currencyCode: ''
    };
    static TRIP_LANGUAGE: { label: string, code: string, disabled: boolean }[] = [
        { label: 'Select Language', code: '', disabled: false },
        { label: 'Bulgarian', code: 'bg', disabled: false },
        { label: 'Croatian', code: 'hr', disabled: false },
        { label: 'Czech', code: 'cs', disabled: false },
        { label: 'Danish', code: 'da', disabled: false },
        { label: 'Dutch', code: 'nl', disabled: false },
        { label: 'English', code: 'en', disabled: false },
        { label: 'Finnish', code: 'fi', disabled: false },
        { label: 'French', code: 'fr', disabled: false },
        { label: 'German', code: 'de', disabled: false },
        { label: 'Greek', code: 'el', disabled: false },
        { label: 'Hungarian', code: 'hu', disabled: false },
        { label: 'Italian', code: 'it', disabled: false },
        { label: 'Norwegian', code: 'nb', disabled: false },
        { label: 'Portuguese', code: 'pt', disabled: false },
        { label: 'Polish', code: 'pl', disabled: false },
        { label: 'Romanian', code: 'ro', disabled: false },
        { label: 'Serbian', code: 'sr', disabled: false },
        { label: 'Slovak', code: 'sk', disabled: false },
        { label: 'Spanish', code: 'es', disabled: false },
        { label: 'Swedish', code: 'sv', disabled: false },
        { label: 'Bosnia', code: 'ba', disabled: false }
    ];
    static DEFAULT_TRIP_REQUEST: ITripRequest = {
        itinerary: {
            bookingno: '',
            commChannelPrefs: [],
            langCode: VendorConstants.TRIP_LANGUAGE[0].code,
            passengers: [JSON.parse(JSON.stringify(VendorConstants.assignTripPrimaryPassenger()))],
            segments: [JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_FLIGHT_SEGMENT))],
            subscriptions: [],
            pricing: JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PRICING))
        }
    };
    static DEFAULT_TRIP_RESPONSE: ITripResponse = {
        invalid_segments: []
    };
    static DEFAULT_TEMPLATE: ITemplate = {
        name: '',
        code: '',
        metadata: {
            lang: [],
            context: [],
            product: []
        }
    };
    static DEFAULT_TEMPLATE_DATA: ITemplateData = {
        // vid: '',
        channel: '',
        channel_key: '',
        productType: '',
        templates: [JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TEMPLATE))]
    };
    static DEFAULT_COMMUNICATION_PREFERENCE: ICommunicationPreferences = {
        channel: {
            name: '',
            key: '',
            meta: {}
        },
        channelMode: '',
        communicateOnGateChange: true,
        communicateOnTerminalChange: true,
        maxNoChangeCommunication: 0,
        minMinsToNotifyForChange: 0,
        minOnTimeHoursToNotify: 0,
        template_codes: [],
        welcomeMsg: false
    };

    static PRODUCT_CONTEXT_DATA: IContextTypeData[] = [
        { name: 'Welcome', selected: false, value: 'FG_WELCOME' },
        { name: 'On-time', selected: false, value: 'FG_ONTIME' },
        { name: 'Delay', selected: false, value: 'FG_DELAY' },
        { name: 'Before Time', selected: false, value: 'FG_BEFORETIME' },
        { name: 'Default', selected: false, value: 'FG_DEFAULT' },
        { name: 'Maybe Canceled', selected: false, value: 'FG_MAYBECANCEL' },
        { name: 'Canceled', selected: false, value: 'FG_CANCELLED' },
    ];
    static PRODUCT_TYPE_DATA: IProductTypeData[] = [
        { name: 'Flight Watcher', selected: false, value: 'FLIGHT_WATCHER' },
        { name: 'Insurance', selected: false, value: 'INSURANCE' },
        { name: 'Auto Checkin', selected: false, value: 'AUTO_CHECKIN' }
    ];
    static CHANNEL_DATA: IChannelData[] = [
        { label: 'Email', value: 'Email', selected: false },
        { label: 'SMS', value: 'Sms', selected: false },
        { label: 'Webhook', value: 'Webhook', selected: false },
        { label: 'WhatsApp', value: 'WhatsApp', selected: false }
    ];

    static LOCKED_PATHS = {
        upcomingTrips: { path: '48-hour-report', id: '48-hour-report' },
        searchBooking: { path: 'search-booking', id: 'search-booking' },
        operationsDataAnalytics: { path: 'operations-data-analytics', id: 'operations-data-analytics' },
        ancillary: { path: 'ancillary', id: 'ancillary-pricing' },
        billingReport: { path: 'billing-report', id: 'billing-report' },
        downloadReport: { path: 'download-report', id: 'download-report' },
        travel: {
            path: 'travel', id: 'travel',
            tripParser: { path: 'data', id: 'data-0' },
            billingParser: { path: 'billing', id: 'data-1' }
        },
        sentSMS: { path: 'send-sms', id: 'send-sms' },
        searchSMS: { path: 'search-sms', id: 'search-sms' },
    }

    static DEFAULT_VENDOR_DATA: IVendorData = {
        email: '',
        alertEmail: '',
        bcc: '',
        password: '',
        org_name: '',
        logo: '',
        vendorPhoneNo: '',
        vendorWebsite: '',
        ipAddress: '',
        address: JSON.parse(JSON.stringify(VendorConstants.DEFAULT_ADDRESS)),
        support: {
            gender: '',
            email: '',
            firstname: '',
            middlename: '',
            lastname: ''
        },
        productTypes: [],
        // keys: {},
        communicationPreferences: []
    }
    public static NAVBAR_LINKS: INavbarLinkUpper[] = [
        {
            id: '0', name: 'Profile', displayDropdown: true, links: [
                { id: '0-0', name: 'Data', path: `/vendor/profile` },
                // { id: '0-1', name: 'Upload Template', path: `/vendor/profile/upload-template` },
            ]
        },
        {
            id: '1', name: 'Trips', displayDropdown: true, links: [
                { id: '1-0', name: 'Create', path: `/vendor/trip/create` },
                { id: '1-2', name: 'Search', path: `/vendor/trip/search` },
                // { id: '1-5', name: 'Parser', path: `/vendor/trip/parser` },
                { id: '1-6', name: 'Bulk Uploader', path: `/vendor/trip/uploader` },
                {
                    id: VendorConstants.LOCKED_PATHS.upcomingTrips.id,
                    name: '48 hour Report',
                    path: `/vendor/trip/${VendorConstants.LOCKED_PATHS.upcomingTrips.path}`
                },
                {
                    id: VendorConstants.LOCKED_PATHS.searchBooking.id,
                    name: 'Search Flight',
                    path: `/vendor/trip/${VendorConstants.LOCKED_PATHS.searchBooking.path}`
                },
                {
                    id: VendorConstants.LOCKED_PATHS.billingReport.id,
                    name: 'Billing Report',
                    path: `/vendor/trip/${VendorConstants.LOCKED_PATHS.billingReport.path}`
                },
                {
                    id: VendorConstants.LOCKED_PATHS.downloadReport.id,
                    name: 'Download Reports',
                    path: `/vendor/trip/${VendorConstants.LOCKED_PATHS.downloadReport.path}`
                }
            ]
        },
        {
            id: VendorConstants.LOCKED_PATHS.travel.id,
            name: 'Travel',
            displayDropdown: true,
            links: [
                { id: VendorConstants.LOCKED_PATHS.travel.tripParser.id, name: 'Trip Parser', path: `/vendor/${VendorConstants.LOCKED_PATHS.travel.path}/${VendorConstants.LOCKED_PATHS.travel.tripParser.path}` },
                // { id: VendorConstants.LOCKED_PATHS.travel.billingParser.id, name: 'Billing Parser', path: `/vendor/${VendorConstants.LOCKED_PATHS.travel.path}/${VendorConstants.LOCKED_PATHS.travel.billingParser.path}` }
            ]
        },
        {
            id: VendorConstants.LOCKED_PATHS.operationsDataAnalytics.id,
            name: 'Analytics',
            displayDropdown: true,
            links: [
                { id: '3-0', name: 'Operations Data Analytics', path: `/vendor/analytics/${VendorConstants.LOCKED_PATHS.operationsDataAnalytics.path}` }
            ]
        },
        {
            id: VendorConstants.LOCKED_PATHS.ancillary.id,
            name: 'Ancillary',
            displayDropdown: true,
            links: [
                { id: '4-0', name: 'Add Ancillary', path: `/vendor/${VendorConstants.LOCKED_PATHS.ancillary.path}` }
            ]
        },
        {
            id: VendorConstants.LOCKED_PATHS.sentSMS.id,
            name: 'SMS',
            displayDropdown: true,
            links: [
                { id: '5-0', name: 'Send SMS', path: `/vendor/sms/${VendorConstants.LOCKED_PATHS.sentSMS.path}` },
                { id: '6-0', name: 'Search SMS', path: `/vendor/sms/${VendorConstants.LOCKED_PATHS.searchSMS.path}` }
            ]
        }
    ];
    public static TRIP_CHANGE_TYPE = {
        NO_CHANGE: 'NO_CHANGE',
        CANCELLED: 'CANCELLED',
        DELAY_DEPART: 'DELAY_DEPART',
        EARLY_DEPART: 'EARLY_DEPART',
        DELAY_LAND: 'DELAY_LAND',
        EARLY_LAND: 'EARLY_LAND',
        NORMAL_LAND: 'NORMAL_LAND',
        DEPART_GATE_CHANGE: 'DEPART_GATE_CHANGE',
        ARRIVAL_GATE_CHANGE: 'ARRIVAL_GATE_CHANGE',
        ARRIVAL_TERMINAL_CHANGE: 'ARRIVAL_TERMINAL_CHANGE',
        DEPART_TERMINAL_CHANGE: 'DEPART_TERMINAL_CHANGE'
    }
    public static VENDOR_ROUTE_ACCESS = {
        // CONVERSATIONAL_AI_ID: 'CH_C',
        TRAVEL_ID: 'CT_DF',
        EMAIL_ID_PRICING: ['care@airlegit.com'],
        EMAIL_ID_ANALYTICS: ['care@airlegit.com', 'travelteam@samgitravel.com'],
        EMAIL_ID_UPCOMING_TRIPS: ['care@airlegit.com', 'service@travelopod.com', 'travelteam@samgitravel.com', 'inteletravel@airlegit.com', "ramzi.rahbani@flighthub.com", "flightguard48@justfly.com", "flightguard48@expedia.com"],
        EMAIL_ID_SEARCH_BOOKING: ['care@airlegit.com', 'travelteam@samgitravel.com', "ramzi.rahbani@flighthub.com", "flightguard48@justfly.com"],
        // 'lukas.brazdil@kiwi.com',
        EMAIL_ID_BILLING_REPORT: ['care@airlegit.com'],
        EMAIL_ID_SEND_SMS: ['care@airlegit.com', 'travelteam@samgitravel.com'],
        EMAIL_ID_SEARCH_SMS: ['care@airlegit.com', 'travelteam@samgitravel.com'],
    }

    static MANDATORY_FIELDS: ITripMandatoryFields = {
        phone: false,
        channelType: false,
    }
    static DISPLAY_FILEDS: ITripDisplayFields = {
        FW_display: false,
        isEmbedded: false,
    }
    static DOWNLOAD_REPORT: string = '/vendor/trip/download-report';
}