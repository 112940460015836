import { BehaviorSubject } from 'rxjs';

export class LoadingService {
    private static instance: LoadingService;
    private loadingSubject: BehaviorSubject<boolean>;

    private constructor() {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
    }

    static getInstance(): LoadingService {
        if (!this.instance) {
            this.instance = new LoadingService();
        }
        return this.instance;
    }

    setLoadingState(isLoading: boolean) {
        this.loadingSubject.next(isLoading);
    }

    getLoadingState(): BehaviorSubject<boolean> {
        return this.loadingSubject;
    }
}
