import { TypeOptions } from "react-toastify";
import { AppConstants } from "../_constants/App.constants";
import { IStorage } from "../_models/storage.interface";
import { ApiService } from "./api.service";
import { saveNotification } from "./logoutNotification.service";
import { StorageProvider } from "./storageProvider.service";
import { UrlProviderService } from "./urlProvider.service";

export class AuthSvc extends UrlProviderService {
    private static instance: AuthSvc;
    private constructor() {
        super('auth-api/auth');
    }
    public static getInstance(): AuthSvc {
        if (!this.instance) {
            this.instance = new AuthSvc();
        }
        return this.instance;
    }

    private storageSvc: IStorage = StorageProvider.getStorage();
    private apiSvc: ApiService = ApiService.getInstance(process.env.REACT_APP_BASE_URL);

    login(data: { uid: string, credential: string }) {
        const uri = this.generateUrl('signin');
        return this.apiSvc.post(uri, data);
    }

    logout(notifications?: { label: string, type: TypeOptions }[]) {
        this.storageSvc.deleteAll();
        if (notifications) {
            notifications.forEach(notification => {
                saveNotification(notification.label, notification.type);
            });
        }
        saveNotification('Logged out', 'success');
        window.location.reload();
    }

    extractAndDecodeTokenAndGetRolePath = (token: string): string | undefined => {
        this.storageSvc.post(AppConstants.TOKEN.AUTH, token);
        const tokenPayload: any = JSON.parse(atob(token.split('.')[1]));
        this.storageSvc.post(AppConstants.TOKEN.DATA, tokenPayload);
        let rolePath = AppConstants.ROLES[tokenPayload.scope];
        if (tokenPayload.scope?.startsWith(AppConstants.OP_ROLE.ROLE)) rolePath = AppConstants.OP_ROLE.PATH;
        return rolePath;
    }

    isLoggedIn = (): boolean => {
        if (!!this.storageSvc.get(AppConstants.TOKEN.AUTH)) return true;
        return false;
    }

    setXALKEY(key: string): string | undefined {
        if (!key) {
            return undefined;
        }

        try {
            // Store the raw token
            this.storageSvc.post(AppConstants.X_ALKEY, key);

            // Decode and store token payload
            // const tokenPayload: any = JSON.parse(atob(token.split('.')[1]));
            // this.storageSvc.post(AppConstants.TOKEN.DATA, tokenPayload);

            // Return the role path based on the token scope
            // return AppConstants.ROLES[tokenPayload.scope];
        } catch (error) {
            console.error('Error setting XALKEY:', error);
            this.storageSvc.delete(AppConstants.X_ALKEY);
            // this.storageSvc.delete(AppConstants.TOKEN.DATA);
            return undefined;
        }
    }

}