// _modules/EmbeddedModule/EmbeddedModuleRouter.tsx
import { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { AuthSvc } from '_services/auth.service';
import TripCreate from '_modules/VendorModule/_pages/TripCreate/TripCreate';
import { ThemeProvider } from 'react-bootstrap';
import { AppConstants } from '_constants/App.constants';
import { VendorDataProvider } from '_contexts/VendorDataContext';
import EmbeddedRouteGuard from './EmbeddedRouteGuard';

interface EmbeddedWrapperProps {
    children: React.ReactNode;
}

const EmbeddedWrapper: React.FC<EmbeddedWrapperProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const authSvc = AuthSvc.getInstance();
    const location = useLocation();

    useEffect(() => {
        const handleTokenFromUrl = () => {
            try {
                const params = new URLSearchParams(location.search);
                const key = params.get('xAlKey'); // Extract the xAlKey

                if (key) {
                    authSvc.setXALKEY(key);
                    setIsAuthenticated(true);
                } else {
                    console.error('Authentication key is missing in the URL.');
                }
            } catch (error) {
                console.error('Error handling embedded authentication:', error);
            }
        };

        handleTokenFromUrl();
    }, [location.search]); // Dependency on location.search ensures it reacts to URL changes.

    if (!isAuthenticated) {
        return <div>Authenticating...</div>;
    }

    return <>{children}</>;
};

const EmbeddedModuleRouter = () => {
    return (
        <VendorDataProvider>
            <ThemeProvider theme={AppConstants.theme}>
                <Routes>
                    <Route element={<EmbeddedWrapper>
                        <Outlet />
                    </EmbeddedWrapper>}
                    >
                        <Route path='trip-create' element={<EmbeddedRouteGuard><TripCreate /></EmbeddedRouteGuard>} />
                    </Route>
                </Routes>
            </ThemeProvider>
        </VendorDataProvider>

    );
};

export default EmbeddedModuleRouter;