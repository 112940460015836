import { CustomConfig, CustomErrorResponse } from "_models/common.interface";
import { LoadingService } from "./loading.service";
import { StorageProvider } from './storageProvider.service';
import axios, { Axios, AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { AppConstants } from '_constants/App.constants';
import { toast } from "react-toastify";
import { saveNotification } from './logoutNotification.service';

export class ApiService {
    private static instances: Map<string, ApiService> = new Map(); // multiple instances for multiple base urls
    private axiosInstance: Axios;
    private loadingSvc = LoadingService.getInstance();
    private storageSvc = StorageProvider.getStorage();

    private readonly defaultConfig: CustomConfig = {
        setLoader: true,
        setAuth: false,
        handleError: true,
    };

    private constructor(baseUrl: string) {
        this.axiosInstance = axios.create({
            baseURL: baseUrl,
        });
        this.axiosInstance.interceptors.request.use(
            async (config: CustomConfig) => {
                return this.requestInterceptor(config);
            },
            (error: AxiosError<CustomErrorResponse>) => {
                this.errorHandler(error);
            }
        );
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => {
                return this.responseInterceptor(response);
            },
            (error: AxiosError<CustomErrorResponse>) => {
                this.errorHandler(error);
            }
        );
    }

    static getInstance(baseUrl: string | undefined): ApiService {
        if (!baseUrl) baseUrl = process.env.REACT_APP_BASE_URL!;
        if (!this.instances.has(baseUrl)) {
            this.instances.set(baseUrl, new ApiService(baseUrl));
        }
        return this.instances.get(baseUrl)!;
    }

    private requestInterceptor(config: CustomConfig): InternalAxiosRequestConfig {
        if (config.setLoader) {
            this.loadingSvc.setLoadingState(true); // set global loading flag true, if setLoader is true
        }
        if (config.setAuth) {
            const authToken = this.storageSvc.get<string>(AppConstants.TOKEN.AUTH);
            if (authToken) {
                if (!config.headers) {
                    config.headers = new AxiosHeaders();
                }
                if (config.headers instanceof AxiosHeaders) {
                    config.headers.set('Authorization', `Bearer ${authToken}`);
                }
            }
            // for embedded x_alkey is used
            const x_alkey = this.storageSvc.get(AppConstants.X_ALKEY);
            if (x_alkey) {
                if (!config.headers) {
                    config.headers = new AxiosHeaders();
                }
                if (config.headers instanceof AxiosHeaders) {
                    config.headers['X-ALKEY'] = x_alkey;
                }
            }
        }
        return config as InternalAxiosRequestConfig;
    }
    private responseInterceptor(response: AxiosResponse): AxiosResponse {
        this.loadingSvc.setLoadingState(false); // set global loading flag false, always
        return response;
    }
    private async errorHandler(error: AxiosError<CustomErrorResponse>) {
        this.loadingSvc.setLoadingState(false); // set global loading flag false, always
        let config: CustomConfig = error.config as CustomConfig;
        if (config?.handleError) {
            switch (error.response?.status) {
                case 401:
                    this.logoutUser(error.response.status, error.response?.data?.message ?? 'Unauthorized');
                    break;
                default:
                    if (error.response?.data) {
                        toast(error.response.data.message, { type: 'error' });
                    } else {
                        toast('Unexpected error occurred', { type: 'error' });
                    }
                    break;
            }
        } else {
            throw error;
        }
    }

    private logoutUser(errorStatus: number, errorMessage: string) {
        this.storageSvc.deleteAll();
        // console.error(`Error: ${errorStatus}. User logged out.`);
        // toast(`Error: ${errorStatus}. User logged out.`, { type: 'error' });
        if (window.location.pathname !== '/login') {
            saveNotification(`Error: ${errorStatus}. ${errorMessage}`, 'error');
            window.location.reload();
        } else {
            toast.error(`Error: ${errorStatus}. ${errorMessage}`);
        }
    }

    get(url: string, custConfig?: CustomConfig) {
        let config: CustomConfig = { ...this.defaultConfig, ...custConfig };
        return this.axiosInstance.get(url, config);
    }
    post(url: string, data: any | null, custConfig?: CustomConfig) {
        let config: CustomConfig = { ...this.defaultConfig, ...custConfig };
        return this.axiosInstance.post(url, data, config);
    }
    put(url: string, data: any | null, custConfig?: CustomConfig) {
        let config: CustomConfig = { ...this.defaultConfig, ...custConfig };
        return this.axiosInstance.put(url, data, config);
    }
    delete(url: string, custConfig?: CustomConfig) {
        let config: CustomConfig = { ...this.defaultConfig, ...custConfig };
        return this.axiosInstance.delete(url, config);
    }
}
