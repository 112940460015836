export const deepCopy = <T>(obj: T): T => {
    if (obj === null || typeof obj !== 'object') return obj;
    if (obj instanceof Date) {
        return new Date(obj.getTime()) as any;
    }
    if (Array.isArray(obj)) {
        return obj.map(item => deepCopy(item)) as any;
    }
    const copy: any = {};
    Object.keys(obj).forEach(key => {
        copy[key] = deepCopy((obj as any)[key]);
    });
    return copy;
}