import React, { useEffect } from 'react';
import styles from './Loader.module.scss';
import { LoadingService } from '_services/loading.service';

interface LoaderProps { }

const Loader: React.FC<LoaderProps> = ({ }: LoaderProps) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const loadingSvc = LoadingService.getInstance();
    const loading$ = loadingSvc.getLoadingState();

    useEffect(() => {
        const sub = loading$.subscribe((loading) => {
            setIsLoading(loading);
        });

        return () => {
            sub.unsubscribe();
        }
    }, [loading$]);

    if (!isLoading) return null;

    return (
        <div className={styles.loaderContainer}>
            <img className={styles.loadingGif} src='/gifs/loader.gif' alt='Loading...' />
        </div>
    );
}

export default Loader;
